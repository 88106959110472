import React, { FC } from "react";
import {
  AlertNotification,
  Button,
  H3,
  Popup,
  PopupHeader,
  SkubotSpinner,
  XIcon,
} from "@commonsku/styles";
import { BASE_ZINDEX } from "../../../popup-factory";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { closePopup } from "../../../actions/popup";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { selectIsLoading, setIsLoadingTrue } from "../../../redux/isLoading";
import { createUpdateTemplate } from "../../../actions/shop";

interface PublishTemplatePopupProps {
  public_view_template_id: string;
}

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const PublishTemplatePopup: FC<PublishTemplatePopupProps> = ({
  public_view_template_id,
}) => {
  const isLoading = useSelector(selectIsLoading);
  const dispatch = useDispatch<ThunkDispatch<any, any, AnyAction>>();

  const onPopupClose = () => dispatch(closePopup());

  const handlePublishTemplate = async () => {
    dispatch(setIsLoadingTrue());
    await dispatch(
      createUpdateTemplate(public_view_template_id, {
        status: "PUBLISHED",
      })
    );
    dispatch(setIsLoadingTrue());
    dispatch(closePopup());
  };

  return (
    <Popup
      overlayZIndex={BASE_ZINDEX + 100}
      zIndex={BASE_ZINDEX + 200}
      style={{ width: 560, height: 224, borderRadius: 10 }}
      header={
        <PopupHeader
          style={{ padding: 0, alignItems: "center", marginBottom: 16 }}
        >
          <H3 style={{ margin: "0px" }} className="title">
            Ready to publish?
          </H3>
          <XIcon
            style={{ cursor: "pointer" }}
            size="large"
            onClick={() => onPopupClose()}
          />
        </PopupHeader>
      }
    >
      <StyledContainer>
        <AlertNotification alertType="warn" style={{ height: 80 }}>
          <span>
            Publishing this template will move it to the ‘Published’ tab and it
            will be available for all commonsku users to use.
          </span>
        </AlertNotification>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "end",
            gap: 16,
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <Button variant="secondary" onClick={onPopupClose} size="medium">
            Cancel
          </Button>
          <Button
            variant={"primary"}
            onClick={handlePublishTemplate}
            size="medium"
            style={{ width: 135 }}
          >
            {isLoading ? (
              <SkubotSpinner
                size="button"
                skubot={false}
                style={{ margin: "auto" }}
              />
            ) : (
              "Publish"
            )}
          </Button>
        </div>
      </StyledContainer>
    </Popup>
  );
};

export default PublishTemplatePopup;
